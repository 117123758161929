import React from "react"
import ReactDOM from "react-dom"

import "typeface-oxanium"
import "typeface-roboto"

import Index from "./pages/index"

ReactDOM.render(<Index />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.register()
